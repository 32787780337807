import styled from "styled-components";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { API_URL } from "../utils/url";

export default function CommentItem(props) {
  const { comment } = props;
  const date = new Date(comment.date).toLocaleDateString();

  const [isReadMore, setIsReadMore] = useState(true);
  const [isReadMoreName, setIsReadMoreName] = useState(true);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  const toggleReadMoreName = () => {
    setIsReadMoreName(!isReadMoreName);
  };

  const isVisible = comment.text.length > 100 ? true : false;
  const isVisibleName = comment.name.length > 10 ? true : false;

  return (
    <Container>
      <Top>
        {comment.photo === null ? (
          <AccountCircleIcon />
        ) : (
          <Image src={`${API_URL}/images/profilePictures/${comment.photo}`} />
        )}
        <h1>
          {isReadMoreName ? comment.name.slice(0, 10) : comment.name}
          <Span onClick={toggleReadMoreName} $isVisible={isVisibleName}>
            {isReadMoreName ? " ..." : " ..."}
          </Span>
        </h1>
        <DateArea>{date}</DateArea>
      </Top>
      <Bottom>
        <TextArea>
          <h2>
            {isReadMore ? comment.text.slice(0, 100) : comment.text}
            <Span onClick={toggleReadMore} $isVisible={isVisible}>
              {isReadMore ? "...devamını oku" : " daha az göster"}
            </Span>
          </h2>
        </TextArea>
        <ButtonArea>
          <div onClick={() => props.deleteAction(comment._id)}>
            <DeleteIcon htmlColor="red" />
          </div>
          {props.acceptAction != null ? (
            <div onClick={() => props.acceptAction(comment._id)}>
              <DoneIcon htmlColor="green" />
            </div>
          ) : null}
        </ButtonArea>
      </Bottom>
    </Container>
  );
}

const Container = styled.div`
  border: 2px solid ${(props) => props.theme.colors.logoOrange};
  background: ${(props) => props.theme.colors.body};
  border-radius: 20px;
  margin-bottom: 10px;
  padding: 10px;
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;

  h1 {
    word-break: break-all;
    font-size: 1.3rem;
  }
  > svg {
    width: 50px;
    height: 50px;
    color: ${(props) => props.theme.colors.logoGreenDark};
    min-width: 50px;
    min-height: 50px;
  }
`;
const Bottom = styled.div``;
const Image = styled.img`
  border-radius: 100%;
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
`;

const TextArea = styled.div`
  align-self: flex-start;
  flex-grow: 2;
  word-break: break-all;
  h2 {
    font-size: 1.2rem;
  }
`;

const DateArea = styled.div`
  margin-left: auto;
`;

const ButtonArea = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  svg {
    font-size: 2rem;
  }

  div {
    padding: 5px;
    border-radius: 100%;
    cursor: pointer;
    :hover {
      background: rgb(200, 200, 200);
    }
  }
`;

const Span = styled.span`
  font-size: 1.6rem;
  color: ${(props) => props.theme.colors.logoGreenDark};
  cursor: pointer;
  display: ${(props) => (props.$isVisible ? "inline" : "none")};
`;
