import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteForm } from "../store/reducers/dataReducer";

export default function Forms() {
  const dispatch = useDispatch();
  const forms = useSelector((state) => state.data.forms);
  const API_KEY = useSelector((state) => state.data.API_KEY);

  const handleDelete = (id) => dispatch(deleteForm({ API_KEY, id }));

  return (
    <>
      {forms.map((form) => {
        return (
          <Item key={form._id}>
            <TextArea>
              <h1>{form.name}</h1>
              <h1>{form.tel}</h1>
              <h1>{form.city}</h1>
            </TextArea>
            <DeleteIcon htmlColor="red" onClick={() => handleDelete(form._id)} />
          </Item>
        );
      })}
    </>
  );
}

const Item = styled.div`
  background: ${(props) => props.theme.colors.body};
  border: 2px solid ${(props) => props.theme.colors.logoOrange};
  border-radius: 20px;
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  svg {
    cursor: pointer;
  }
`;

const TextArea = styled.div`
  align-self: flex-start;
  flex-grow: 2;
  word-break: break-all;
`;
