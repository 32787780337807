import { ThemeProvider } from "styled-components";
import { useSelector } from "react-redux";

const LightTheme = {
  colors: {
    body: "rgb(255,255,255)",
    background1: "rgb(210,210,210)",
    background2: "rgb(150,150,150)",
    text: "rgb(20,20,20)",
    logoWhite: "rgb(252, 252, 252)",
    logoGreen: "rgb(130, 176, 150)",
    logoGreenDark: "rgb(0,128,98)",
    logoOrange: "rgb(240,137,62)",
  },
};
const DarkTheme = {
  colors: {
    body: "rgb(0,0,0)",
    background1: "rgb(45,45,45)",
    background2: "rgb(100,100,100)",
    text: "rgb(200,200,200)",
    logoWhite: "rgb(252, 252, 252)",
    logoGreen: "rgb(130, 176, 150)",
    logoGreenDark: "rgb(0,128,98)",
    logoOrange: "rgb(240,137,62)",
  },
};

const Theme = ({ children }) => {
  const darkMode = useSelector((state) => state.theme.darkMode);

  return <ThemeProvider theme={darkMode === true ? DarkTheme : LightTheme}>{children}</ThemeProvider>;
};

export default Theme;
