import { useSelector, useDispatch } from "react-redux";
import { deleteComment } from "../store/reducers/dataReducer";
import CommentItem from "../components/CommentItem";

export default function Comments() {
  const dispatch = useDispatch();
  const comments = useSelector((state) => state.data.comments);
  const API_KEY = useSelector((state) => state.data.API_KEY);

  const handleDelete = (id) => dispatch(deleteComment({ API_KEY, id }));

  return (
    <>
      {comments.map((comment) => {
        return <CommentItem key={comment._id} comment={comment} deleteAction={handleDelete} />;
      })}
    </>
  );
}
