import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  API_KEY: null,
  comments: [],
  commentRequests: [],
  forms: [],
  photos: [],
  selectedPhotos: [],
  blogs: [],
};

const dataSlice = createSlice({
  name: "dataSlice",
  initialState,
  reducers: {
    setAPI_KEY: (state, action) => {
      state.API_KEY = action.payload;
    },
    initialRequests: () => {},
    signIn: () => {},
    getComments: () => {},
    setComments: (state, action) => {
      state.comments = action.payload;
    },
    deleteComment: () => {},
    getCommentRequests: () => {},
    setCommentRequests: (state, action) => {
      state.commentRequests = action.payload;
    },
    deleteCommentRequest: () => {},
    acceptCommentRequest: () => {},
    getForms: () => {},
    setForms: (state, action) => {
      state.forms = action.payload;
    },
    deleteForm: () => {},
    getPhotos: () => {},
    setPhotos: (state, action) => {
      state.photos = action.payload;
    },
    addPhoto: () => {},
    deletePhoto: () => {},
    updatePhoto: () => {},
    setSelectedPhotos: (state, action) => {
      state.selectedPhotos = action.payload;
    },
    addToSelectedPhotos: (state, action) => {
      state.selectedPhotos = [...state.selectedPhotos, action.payload];
    },
    updateSelectedPhotos: (state, action) => {
      let selectedPhotos = state.selectedPhotos;
      for (let i = 0; i < state.selectedPhotos.length; i++) {
        if (i === action.payload.id) selectedPhotos[i] = { ...selectedPhotos[i], state: action.payload.state };
      }
      state.selectedPhotos = selectedPhotos;
    },
    getBlogs: () => {},
    setBlogs: (state, action) => {
      state.blogs = action.payload;
    },
  },
});

export const {
  setAPI_KEY,
  initialRequests,
  signIn,
  getComments,
  setComments,
  deleteComment,
  getCommentRequests,
  setCommentRequests,
  deleteCommentRequest,
  acceptCommentRequest,
  getForms,
  setForms,
  deleteForm,
  getPhotos,
  setPhotos,
  addPhoto,
  deletePhoto,
  updatePhoto,
  setSelectedPhotos,
  addToSelectedPhotos,
  updateSelectedPhotos,
  getBlogs,
  setBlogs,
} = dataSlice.actions;

export default dataSlice.reducer;
