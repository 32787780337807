import { Route, Routes } from "react-router-dom";

import LoginPage from "../pages/LoginPage";
import HomePage from "../pages/HomePage";
import Comments from "../pages/Comments";
import CommentRequests from "../pages/CommentRequests";
import Photos from "../pages/Photos";
import PhotoAdd from "../pages/PhotoAdd";
import Forms from "../pages/Forms";
import Blogs from "../pages/Blogs";
import BlogAdd from "../pages/BlogAdd";
import BlogOne from "../pages/BlogOne";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/yorumlar" element={<Comments />} />
      <Route path="/yorum-istekleri" element={<CommentRequests />} />
      <Route path="/fotograflar" element={<Photos />} />
      <Route path="/fotograf-ekle" element={<PhotoAdd />} />
      <Route path="/formlar" element={<Forms />} />
      <Route path="/yazilar" element={<Blogs />} />
      <Route path="/yazi-ekle" element={<BlogAdd />} />
      <Route path="/yazilar/:id" element={<BlogOne />} />
    </Routes>
  );
};

export const LoginRoutes = () => {
  return (
    <Routes>
      <Route path="/*" element={<LoginPage />} />
    </Routes>
  );
};
