export const request = async (url, method, body = false, headers = false) => {
  const options = {
    method,
  };

  if (body) {
    options.body = body;
  }
  if (headers) {
    options.headers = headers;
  }

  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return await response.json();
  } catch (e) {
    return "invalid";
  }
};
