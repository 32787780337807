import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import GlobalStyle from "./styles/global";
import { Provider } from "react-redux";
import createSagaMiddleware from "redux-saga";
import { configureStore } from "@reduxjs/toolkit";
import Toast from "./components/Toast";
import sagas from "./store/sagas";
import Theme from "./styles/Theme";

import dataReducer from "./store/reducers/dataReducer";
import themeReducer from "./store/reducers/themeReducer";
import toastReducer from "./store/reducers/toastReducer";

const reducer = {
  data: dataReducer,
  theme: themeReducer,
  toast: toastReducer,
};

const devMode = process.env.NODE_ENV === "development";
const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];
const store = configureStore({
  reducer,
  devTools: devMode,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      thunk: false,
      serializableCheck: false,
    }),
    ...middleware,
  ],
});
sagaMiddleware.run(sagas);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Theme>
        <GlobalStyle />
        <App />
        <Toast />
      </Theme>
    </BrowserRouter>
  </Provider>
);
