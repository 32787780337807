import styled from "styled-components";
import Add from "@mui/icons-material/Add";

export default function AddIcon(props) {
  return (
    <Container onClick={props.onClick}>
      <Add />
    </Container>
  );
}

const Container = styled.div`
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.logoGreenDark};
  border-radius: 100%;
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 50px;
  right: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    color: white;
  }
`;
