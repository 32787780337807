import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppRoutes, LoginRoutes } from "./components/Router";
import Navigation from "./components/Navigation";
import { initialRequests } from "./store/reducers/dataReducer";
import Page from "./styles/Page";

export default function App() {
  const API_KEY = useSelector((state) => state.data.API_KEY);

  return <>{API_KEY ? <AppFlow /> : <LoginFlow />}</>;
}

const AppFlow = () => {
  const dispatch = useDispatch();
  const API_KEY = useSelector((state) => state.data.API_KEY);

  useEffect(() => {
    dispatch(initialRequests({ API_KEY }));
  }, [API_KEY, dispatch]);
  return (
    <>
      <Navigation />
      <Page>
        <AppRoutes />
      </Page>
    </>
  );
};

const LoginFlow = () => {
  return <LoginRoutes />;
};
