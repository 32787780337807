import styled from "styled-components";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import ChangeThemeSwitch from "./ChangeThemeSwitch";
import MenuIcon from "@mui/icons-material/Menu";
import MuiDrawer from "./Drawer";
import { useState } from "react";

export default function Navigation() {
  const commentsLength = useSelector((state) => state.data.comments.length);
  const commentRequestsLength = useSelector((state) => state.data.commentRequests.length);
  const formsLength = useSelector((state) => state.data.forms.length);
  const photosLength = useSelector((state) => state.data.photos.length);
  const blogsLength = useSelector((state) => state.data.blogs.length);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleClose = () => setDrawerOpen(false);

  return (
    <Container>
      <Menu>
        <MenuIcon onClick={() => setDrawerOpen(true)} />
      </Menu>
      <MuiDrawer open={drawerOpen} onClose={handleClose} />
      <LinkArea>
        <Link to="yorumlar">
          Yorumlar <span>{commentsLength}</span>
        </Link>
        <Link to="yorum-istekleri">
          Yorum İstekleri <span>{commentRequestsLength}</span>
        </Link>
        <Link to="fotograflar">
          Fotoğraflar <span>{photosLength}</span>
        </Link>
        <Link to="formlar">
          Formlar <span>{formsLength}</span>
        </Link>
        <Link to="yazilar">
          Yazılar <span>{blogsLength}</span>
        </Link>
        <ChangeThemeSwitch />
      </LinkArea>
    </Container>
  );
}

const Container = styled.nav`
  background: ${(props) => props.theme.colors.logoGreenDark};
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 0;
  position: sticky;
  top: 0;
  height: 50px;
  z-index: 9999;
`;

const Menu = styled.div`
  @media screen and (min-width: 800px) {
    display: none;
  }

  svg {
    color: white;
  }
`;

const LinkArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

const Link = styled(NavLink)`
  background: white;
  color: black;
  text-decoration: none;
  border-radius: 5px;
  padding: 5px;
`;
