import styled from "styled-components";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import DoneIcon from "@mui/icons-material/Done";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { addPhoto, setSelectedPhotos, addToSelectedPhotos } from "../store/reducers/dataReducer";

export default function PhotoAdd() {
  const dispatch = useDispatch();
  const API_KEY = useSelector((state) => state.data.API_KEY);
  const selectedPhotos = useSelector((state) => state.data.selectedPhotos);

  useEffect(() => {
    dispatch(setSelectedPhotos([]));
  }, [dispatch]);

  const handleFileChose = (event) => {
    const files = event.target.files;
    dispatch(setSelectedPhotos([]));

    for (let i = 0; i < files.length; i++) {
      if (!files[i]) continue;
      if (!files[i].type.includes("image/")) continue;
      dispatch(
        addToSelectedPhotos({
          file: files[i],
          url: URL.createObjectURL(files[i]),
          state: null,
          id: i,
        })
      );
    }
  };
  const handleAdd = () => dispatch(addPhoto({ API_KEY, files: selectedPhotos }));

  return (
    <Container>
      <FileChoose>
        <label htmlFor="file-upload">Dosya Seç</label>
        <input type="file" id="file-upload" multiple onChange={handleFileChose} accept="image/*" />
        {selectedPhotos.length !== 0 ? <button onClick={handleAdd}>Ekle</button> : null}
      </FileChoose>
      {selectedPhotos.map((photo) => {
        return (
          <Item key={photo.id}>
            <img src={photo.url} alt="" />
            {imageState(photo.state)}
          </Item>
        );
      })}
    </Container>
  );
}

const imageState = (state) => {
  switch (state) {
    case "loading":
      return <CircularProgress />;
    case "done":
      return <DoneIcon />;
    case "error":
      return <ErrorOutlineIcon />;
    default:
      return null;
  }
};

const Container = styled.div`
  padding-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const FileChoose = styled.div`
  width: 100%;
  grid-column: 1 / -1;
  display: flex;
  align-content: center;
  justify-content: center;
  gap: 50px;

  input[type="file"] {
    display: none;
  }
  label {
    border: none;
    background: ${(props) => props.theme.colors.logoGreenDark};
    color: white;
    padding: 10px;
    cursor: pointer;
    font-size: 1.5rem;
  }
  button {
    border: none;
    background: ${(props) => props.theme.colors.logoGreenDark};
    color: white;
    padding: 10px;
    cursor: pointer;
    font-size: 1.5rem;
  }
`;

const Item = styled.div`
  border: 2px solid ${(props) => props.theme.colors.logoGreenDark};
  border-radius: 10px;
  padding: 5px;
  display: flex;
  img {
    width: 80%;
    aspect-ratio: 1/1;
    object-fit: cover;
  }

  svg {
    width: 20%;
    justify-self: center;
    align-self: center;
  }
`;
