import styled from "styled-components";
import Switch from "@mui/material/Switch";
import { useDispatch, useSelector } from "react-redux";
import { switchLight, switchDark } from "../store/reducers/themeReducer";

export default function ChangeThemeSwitch() {
  const dispatch = useDispatch();
  const darkMode = useSelector((state) => state.theme.darkMode);

  const changeTheme = () => {
    darkMode === true ? dispatch(switchLight()) : dispatch(switchDark());
  };

  return <MaterialUISwitch onChange={changeTheme} />;
}

const MaterialUISwitch = styled(Switch)`
  width: 62;
  height: 34;
  padding: 7;
`;
