import { all, call, put, take } from "redux-saga/effects";
import { request } from "../utils/request";
import { API_URL } from "../utils/url";
import * as actions from "./reducers/dataReducer";
import { showToast } from "./reducers/toastReducer";

function* initialRequests(payload) {
  const API_KEY = payload.API_KEY;
  yield all([getComments(), getCommentRequests({ API_KEY }), getForms({ API_KEY }), getPhotos(), getBlogs()]);
}
function* signIn(payload) {
  let formData = new FormData();
  formData.append("password", payload.password);

  const response = yield call(request, `${API_URL}/login`, "POST", formData);
  if (response !== "invalid" && response.status) {
    yield put(actions.setAPI_KEY(response.API_KEY));
  }
}
function* getComments() {
  const response = yield call(request, `${API_URL}/comment`, "GET");
  if (response !== "invalid" && response.status) {
    yield put(actions.setComments(response.data));
  }
}
function* deleteComment(payload) {
  let formData = new FormData();
  formData.append("id", payload.id);
  const response = yield call(request, `${API_URL}/comment/delete`, "POST", formData, { "api-key": payload.API_KEY });
  if (response !== "invalid" && response.status) {
    yield put(showToast("Yorum Silindi"));
    yield call(getComments);
  }
}
function* getCommentRequests(payload) {
  const response = yield call(request, `${API_URL}/comment-request`, "GET", false, { "api-key": payload.API_KEY });
  if (response !== "invalid" && response.status) {
    yield put(actions.setCommentRequests(response.data));
  }
}
function* deleteCommentRequest(payload) {
  let formData = new FormData();
  formData.append("id", payload.id);
  const response = yield call(request, `${API_URL}/comment-request/delete`, "POST", formData, {
    "api-key": payload.API_KEY,
  });
  if (response !== "invalid" && response.status) {
    yield put(showToast("Yorum İsteği Silindi"));
    yield call(getComments);
    yield call(getCommentRequests, { API_KEY: payload.API_KEY });
  }
}
function* acceptCommentRequest(payload) {
  let formData = new FormData();
  formData.append("id", payload.id);

  const response = yield call(request, `${API_URL}/comment-request/accept`, "POST", formData, {
    "api-key": payload.API_KEY,
  });
  if (response !== "invalid" && response.status) {
    yield put(showToast("Yorum Kabul Edildi"));
    yield call(getComments);
    yield call(getCommentRequests, { API_KEY: payload.API_KEY });
  }
}
function* getForms(payload) {
  const response = yield call(request, `${API_URL}/form`, "GET", false, { "api-key": payload.API_KEY });
  if (response !== "invalid" && response.status) {
    yield put(actions.setForms(response.data));
  }
}
function* deleteForm(payload) {
  let formData = new FormData();
  formData.append("id", payload.id);

  const response = yield call(request, `${API_URL}/form/delete`, "POST", formData, { "api-key": payload.API_KEY });
  if (response !== "invalid" && response.status) {
    yield put(showToast("Form Silindi"));
    yield call(getForms, { API_KEY: payload.API_KEY });
  }
}
function* getPhotos() {
  const response = yield call(request, `${API_URL}/photo`, "GET");
  if (response !== "invalid" && response.status) {
    yield put(actions.setPhotos(response.data));
  }
}
function* addPhoto(payload) {
  for (let i = 0; i < payload.files.length; i++) {
    const photoId = payload.files[i].id;
    yield put(actions.updateSelectedPhotos({ id: photoId, state: "loading" }));
    let formData = new FormData();
    formData.append("photo", payload.files[i].file);

    const response = yield call(request, `${API_URL}/photo`, "POST", formData, { "api-key": payload.API_KEY });
    if (response !== "invalid") {
      if (response.status === true) yield put(actions.updateSelectedPhotos({ id: photoId, state: "done" }));
      else yield put(actions.updateSelectedPhotos({ id: photoId, state: "error" }));
    }
  }
  yield call(getPhotos);
}
function* deletePhoto(payload) {
  let formData = new FormData();
  formData.append("id", payload.id);

  const response = yield call(request, `${API_URL}/photo/delete`, "POST", formData, { "api-key": payload.API_KEY });
  if (response !== "invalid" && response.status) {
    yield put(showToast("Fotoğraf Silindi"));
    yield call(getPhotos);
  }
}
function* updatePhoto(payload) {
  let formData = new FormData();
  formData.append("id", payload.id);
  formData.append("newIndex", payload.newIndex);

  const response = yield call(request, `${API_URL}/photo`, "PUT", formData, { "api-key": payload.API_KEY });
  if (response !== "invalid" && response.status) {
    yield put(showToast("Fotoğraf Güncellendi"));
    yield call(getPhotos);
  }
}
function* getBlogs() {
  const response = yield call(request, `${API_URL}/blog`, "GET");
  if (response !== "invalid" && response.status) {
    yield put(actions.setBlogs(response.data));
  }
}

// Watchers
function* initialRequestsWatcher() {
  while (true) {
    const action = yield take(actions.initialRequests);
    yield call(initialRequests, action.payload);
  }
}
function* signInWatcher() {
  while (true) {
    const action = yield take(actions.signIn);
    yield call(signIn, action.payload);
  }
}
function* getCommentsWatcher() {
  while (true) {
    const action = yield take(actions.getComments);
    yield call(getComments, action.payload);
  }
}
function* deleteCommentWatcher() {
  while (true) {
    const action = yield take(actions.deleteComment);
    yield call(deleteComment, action.payload);
  }
}
function* getCommentRequestsWatcher() {
  while (true) {
    const action = yield take(actions.getCommentRequests);
    yield call(getCommentRequests, action.payload);
  }
}
function* deleteCommentRequestWatcher() {
  while (true) {
    const action = yield take(actions.deleteCommentRequest);
    yield call(deleteCommentRequest, action.payload);
  }
}
function* acceptCommentRequestWatcher() {
  while (true) {
    const action = yield take(actions.acceptCommentRequest);
    yield call(acceptCommentRequest, action.payload);
  }
}
function* getFormsWatcher() {
  while (true) {
    const action = yield take(actions.getForms);
    yield call(getForms, action.payload);
  }
}
function* deleteFormWatcher() {
  while (true) {
    const action = yield take(actions.deleteForm);
    yield call(deleteForm, action.payload);
  }
}
function* getPhotosWatcher() {
  while (true) {
    const action = yield take(actions.getPhotos);
    yield call(getPhotos, action.payload);
  }
}
function* addPhotoWatcher() {
  while (true) {
    const action = yield take(actions.addPhoto);
    yield call(addPhoto, action.payload);
  }
}
function* deletePhotoWatcher() {
  while (true) {
    const action = yield take(actions.deletePhoto);
    yield call(deletePhoto, action.payload);
  }
}
function* updatePhotoWatcher() {
  while (true) {
    const action = yield take(actions.updatePhoto);
    yield call(updatePhoto, action.payload);
  }
}
function* getBlogsWatcher() {
  while (true) {
    const action = yield take(actions.getBlogs);
    yield call(getBlogs, action.payload);
  }
}

export default function* rootSaga() {
  yield all([
    initialRequestsWatcher(),
    signInWatcher(),
    getCommentsWatcher(),
    deleteCommentWatcher(),
    getCommentRequestsWatcher(),
    deleteCommentRequestWatcher(),
    acceptCommentRequestWatcher(),
    getFormsWatcher(),
    deleteFormWatcher(),
    getPhotosWatcher(),
    addPhotoWatcher(),
    deletePhotoWatcher(),
    updatePhotoWatcher(),
    getBlogsWatcher(),
  ]);
}
