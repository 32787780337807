import { useSelector, useDispatch } from "react-redux";
import { deleteCommentRequest, acceptCommentRequest } from "../store/reducers/dataReducer";
import CommentItem from "../components/CommentItem";

export default function CommentRequests() {
  const dispatch = useDispatch();
  const commentRequests = useSelector((state) => state.data.commentRequests);
  const API_KEY = useSelector((state) => state.data.API_KEY);

  const handleDelete = (id) => dispatch(deleteCommentRequest({ API_KEY, id }));
  const handleAccept = (id) => dispatch(acceptCommentRequest({ API_KEY, id }));

  return (
    <>
      {commentRequests.map((commentRequest) => {
        return <CommentItem key={commentRequest._id} comment={commentRequest} deleteAction={handleDelete} acceptAction={handleAccept} />;
      })}
    </>
  );
}
