import styled from "styled-components";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import { deletePhoto, updatePhoto } from "../store/reducers/dataReducer";
import { API_URL } from "../utils/url";

export default function Photo(props) {
  const { photo } = props;
  const dispatch = useDispatch();
  const API_KEY = useSelector((state) => state.data.API_KEY);
  const [showEdit, setShowEdit] = useState(false);
  const [inputState, setInputState] = useState(photo.index);

  const toggleEdit = () => setShowEdit(!showEdit);
  const closeEdit = () => setShowEdit(false);

  const handleDelete = () => {
    dispatch(
      deletePhoto({
        API_KEY,
        id: photo._id,
      })
    );
  };

  const handleEdit = () => {
    dispatch(
      updatePhoto({
        API_KEY,
        id: photo._id,
        newIndex: inputState,
      })
    );
    closeEdit();
  };

  return (
    <Container src={`${API_URL}/images/customerPictures/${photo.name}`}>
      <Icon onClick={handleDelete} align="left" type="delete">
        <DeleteIcon />
      </Icon>
      <Icon onClick={toggleEdit} align="right">
        <EditIcon />
      </Icon>
      <EditInput $show={showEdit}>
        <input type="number" value={inputState} onChange={(e) => setInputState(e.target.value)} />
        <div onClick={handleEdit}>
          <DoneIcon />
        </div>
      </EditInput>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  aspect-ratio: 1/1;
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  :hover {
    opacity: 0.9;
  }
`;

const Icon = styled.div`
  background: black;
  color: white;
  border-radius: 100%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  left: ${(props) => (props.align === "left" ? "0" : "")};
  right: ${(props) => (props.align === "right" ? "0" : "")};
  top: 0;

  svg {
    color: ${(props) => (props.type === "delete" ? "rgb(255,50,50)" : `${props.theme.colors.logoGreen}`)};
  }
`;

const EditInput = styled.div`
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 40%;
  width: 100%;
  display: ${(props) => (props.$show ? "flex" : "none")};
  gap: 5px;

  input[type="number"] {
    padding: 5px 0 5px 5px;
    font-size: 1.5rem;
    border: none;
    outline: none;

    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    width: 50%;
  }
  > div {
    background: white;
    border-radius: 100%;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
      color: ${(props) => props.theme.colors.logoGreen};
    }
  }
`;
