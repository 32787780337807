import styled from "styled-components";
import { useState } from "react";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../utils/url";
import { getBlogs } from "../store/reducers/dataReducer";
import { showToast } from "../store/reducers/toastReducer";

export default function BlogAdd() {
  const navigate = useNavigate();
  const darkMode = useSelector((state) => state.theme.darkMode);
  const dispatch = useDispatch();
  const API_KEY = useSelector((state) => state.data.API_KEY);
  const [markdown, setMarkdown] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [coverFile, setCoverFile] = useState();
  const [imageURL, setImageURL] = useState(process.env.PUBLIC_URL + "/logo.png");

  const handleFileChose = (event) => {
    if (!event.target.files[0]) return;
    if (!event.target.files[0].type.includes("image/")) return;
    const image = event.target.files[0];
    setCoverFile(image);
    setImageURL(URL.createObjectURL(image));
  };

  const handleAddBlog = async () => {
    let formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("markdown", markdown);
    formData.append("photo", coverFile);
    let res = await fetch(`${API_URL}/blog`, {
      method: "POST",
      headers: { "api-key": API_KEY },
      body: formData,
    });
    res = await res.json();
    if (res.status === true) {
      dispatch(getBlogs());
      navigate("/yazilar");
      dispatch(showToast("Yazı Eklendi"));
    }
  };

  return (
    <>
      <div data-color-mode={darkMode === true ? "dark" : "light"}>
        <MDEditor
          height={300}
          value={markdown}
          onChange={setMarkdown}
          previewOptions={{
            rehypePlugins: [[rehypeSanitize]],
          }}
        />
      </div>
      <Container>
        <InputField>
          <div>
            <label htmlFor="title">Başlık</label>
            <input
              name="title"
              id="title"
              type="text"
              placeholder="Başlık"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="description">Açıklama</label>
            <input
              name="description"
              id="description"
              type="text"
              placeholder="Açıklama"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div>
            <label className="label" htmlFor="cover">
              Fotoğraf Seç
            </label>
            <input id="cover" type="file" onChange={handleFileChose} accept="image/*" style={{ display: "none" }} />
            <img src={imageURL} alt="" />
          </div>
          <div className="button">
            <button onClick={handleAddBlog}>Kaydet</button>
          </div>
        </InputField>
      </Container>
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InputField = styled.div`
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  input {
    margin-left: 10px;
    padding: 10px;
    align-self: flex-end;
  }
  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }
  .button {
    display: flex;
    justify-content: center;
    button {
      padding: 5px;
    }
  }

  .label {
    background-color: ${(props) => props.theme.colors.logoGreenDark};
    padding: 5px;
  }
`;
