import styled from "styled-components";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Drawer from "@mui/material/Drawer";

export default function MuiDrawer(props) {
  const { open, onClose } = props;
  const commentsLength = useSelector((state) => state.data.comments.length);
  const commentRequestsLength = useSelector((state) => state.data.commentRequests.length);
  const formsLength = useSelector((state) => state.data.forms.length);
  const photosLength = useSelector((state) => state.data.photos.length);
  const blogsLength = useSelector((state) => state.data.blogs.length);

  return (
    <Drawer open={open} onClose={onClose}>
      <Container>
        <Link to="yorumlar" onClick={onClose}>
          Yorumlar <span>{commentsLength}</span>
        </Link>
        <Link to="yorum-istekleri" onClick={onClose}>
          Yorum İstekleri <span>{commentRequestsLength}</span>
        </Link>
        <Link to="fotograflar" onClick={onClose}>
          Fotoğraflar <span>{photosLength}</span>
        </Link>
        <Link to="formlar" onClick={onClose}>
          Formlar <span>{formsLength}</span>
        </Link>
        <Link to="yazilar" onClick={onClose}>
          Yazılar <span>{blogsLength}</span>
        </Link>
      </Container>
    </Drawer>
  );
}

const Container = styled.div`
  padding: 70px 20px 0;
  background: ${(props) => props.theme.colors.body};
  display: flex;
  flex-direction: column;
  height: 100vh;
  gap: 10px;
`;

const Link = styled(NavLink)`
  background: ${(props) => props.theme.colors.body};
  color: ${(props) => props.theme.colors.text};
  text-decoration: none;
  border-radius: 5px;
  padding: 5px;
`;
