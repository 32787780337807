import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AddIcon from "../components/AddIcon";
import { API_URL } from "../utils/url";

export default function Blogs() {
  const blogs = useSelector((state) => state.data.blogs);
  const navigate = useNavigate();

  return (
    <>
      <Container>
        {blogs.map((blog) => {
          return (
            <Item key={blog._id} onClick={() => navigate(`/yazilar/${blog.id}`)}>
              <img
                src={
                  blog.photo != null
                    ? `${API_URL}/images/blogPictures/${blog.photo}`
                    : `${process.env.PUBLIC_URL}/logo.png`
                }
                alt="cover"
              />
              <p>{blog.title}</p>
            </Item>
          );
        })}
      </Container>
      <AddIcon onClick={() => navigate("/yazi-ekle")} />
    </>
  );
}
const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  padding: 20px 10px;

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr 1fr;
  }
`;
const Item = styled.div`
  cursor: pointer;
  border: 2px solid ${(props) => props.theme.colors.logoGreenDark};
  background: ${(props) => props.theme.colors.body};
  border-radius: 5px;
  img {
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
  }
  p {
    text-align: center;
  }
`;
