import styled from "styled-components";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { signIn } from "../store/reducers/dataReducer";

export default function LoginPage() {
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(signIn({ password }));
  };

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <InputArea>
          <input placeholder="Şifre" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
          <button type="submit">Giriş</button>
        </InputArea>
      </form>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: ${(props) => props.theme.colors.logoGreen};
  padding: 20px;
  input {
    padding: 10px;
    border: 2px solid ${(props) => props.theme.colors.logoGreenDark};
    outline: none;
  }
  button {
    color: white;
    text-align: center;
    cursor: pointer;
    background: ${(props) => props.theme.colors.logoGreenDark};
    border: none;
    padding: 5px;
  }
`;
