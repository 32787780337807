import styled from "styled-components";

export default function Page({ children }) {
  return (
    <PageContainer>
      <Container>{children}</Container>
    </PageContainer>
  );
}

const PageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  width: 75%;
  background: ${(props) => props.theme.colors.background1};
  min-height: calc(100vh - 50px);
  padding: 25px 20px;

  @media screen and (max-width: 800px) {
    width: 85%;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;
