import styled from "styled-components";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";
import { API_URL } from "../utils/url";
import { getBlogs } from "../store/reducers/dataReducer";
import { showToast } from "../store/reducers/toastReducer";

export default function BlogOne() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const API_KEY = useSelector((state) => state.data.API_KEY);
  const darkMode = useSelector((state) => state.theme.darkMode);
  const { id } = useParams();
  const [blog, setBlog] = useState({ markdown: "" });

  useEffect(() => {
    const fetchData = async () => {
      let res = await fetch(`${API_URL}/blog/${id}`);
      res = await res.json();
      if (res.status === true) {
        setBlog(res.data);
      }
    };

    fetchData();
  }, [id]);

  const handleDelete = async () => {
    let res = await fetch(`${API_URL}/blog/${id}`, { method: "DELETE", headers: { "api-key": API_KEY } });
    res = await res.json();
    if (res.status === true) {
      dispatch(getBlogs());
      navigate("/yazilar");
      dispatch(showToast(`${id} Silindi`));
    }
  };

  return (
    <>
      <div data-color-mode={darkMode === true ? "dark" : "light"}>
        <MDEditor
          hideToolbar={true}
          height={300}
          value={blog.markdown}
          previewOptions={{
            rehypePlugins: [[rehypeSanitize]],
          }}
        />
      </div>
      <DeleteBlog>
        <div onClick={handleDelete}>Yazıyı Sil</div>
      </DeleteBlog>
    </>
  );
}

const DeleteBlog = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: center;

  > div {
    cursor: pointer;
    background-color: red;
    padding: 10px;
  }
`;
