import styled from "styled-components";

export default function HomePage() {
  return <Container>Anasayfa</Container>;
}
const Container = styled.div`
  width: 100%;
  height: 200px;
  background-color: ${(props) => props.theme.colors.background};
`;
