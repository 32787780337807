import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  toast: false,
  toastMessage: "",
};

const toastSlice = createSlice({
  name: "toastSlice",
  initialState,
  reducers: {
    showToast: (state, action) => {
      state.toast = true;
      state.toastMessage = action.payload;
    },
    hideToast: (state) => {
      state.toast = false;
    },
  },
});

export const { showToast, hideToast } = toastSlice.actions;

export default toastSlice.reducer;
