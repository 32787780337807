import styled from "styled-components";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Photo from "../components/Photo";
import AddIcon from "../components/AddIcon";

export default function Photos() {
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const photos = useSelector((state) => state.data.photos);
  const count = Math.ceil(photos.length / 10);

  const array = photos.slice((page - 1) * 10, page * 10);

  const handleChange = (event, value) => setPage(value);
  return (
    <>
      <Container length={photos.length}>
        <ButtonArea>
          <Pagination count={count} page={page} onChange={handleChange} />
        </ButtonArea>
        {array.map((photo) => {
          return <Photo key={photo._id} photo={photo} />;
        })}
      </Container>
      <AddIcon onClick={() => navigate("/fotograf-ekle")} />
    </>
  );
}

const Container = styled.div`
  display: ${(props) => (props.length === 0 ? "none" : "grid")};
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  padding: 20px 10px;

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const ButtonArea = styled.div`
  grid-column: 1/-1;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    color: ${(props) => props.theme.colors.text};
  }
`;
