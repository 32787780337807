import styled from "styled-components";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { hideToast } from "../store/reducers/toastReducer";

export default function Toast() {
  const open = useSelector((state) => state.toast.toast);
  const message = useSelector((state) => state.toast.toastMessage);
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(hideToast());
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={open}
      autoHideDuration={2000}
      onClose={handleClose}
      children={
        <Container>
          {message}
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="medium" />
          </IconButton>
        </Container>
      }
    />
  );
}

const Container = styled.div`
  text-align: center;
  background: ${(props) => props.theme.colors.logoGreenDark};
  padding: 1rem;
  font-size: 1.6rem;
  color: ${(props) => props.theme.colors.logoWhite};
  border: 2px solid ${(props) => props.theme.colors.logoOrange};
  border-radius: 20px;
`;
