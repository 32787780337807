import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body{
    font-family: 'Roboto', sans-serif;
    background-color: ${(props) => props.theme.colors.body};
    color: ${(props) => props.theme.colors.text}

    
  }

  ::-webkit-scrollbar{
    width: 10px;
  }
  ::-webkit-scrollbar-track {
  background: none; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: ${(props) => props.theme.colors.logoOrange}; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: ${(props) => props.theme.colors.logoGreen}; 
}

  html{
    overflow-y: overlay;
  }
`;

export default GlobalStyle;
